/****** Common CSS*/
.invoice-width {
	max-width: 2480px;
}

.letter-space {
	letter-spacing:6px;
}

.card-width {
	max-width: 100px;
}

.form-group.required  { 
    .control-label:after,
    .col-form-label:after
     {
        content:"*";
        color:red;
    }
 }