/** Override Bootstrap variables **/
$primary : #e74a3b; 

$input-btn-focus-width: .0rem !default;
$input-btn-focus-color:  rgba( #fff, .25) !default;
$input-btn-focus-box-shadow: 0 0 0 0  !default;
 
$nav-pills-border-radius: 3rem !default;
 
$anchor-hover-color : rgba(255,255,255,.9); 
$nav-links-color : rgba(255,255,255,.5); 
$active-nav-link: #fff;