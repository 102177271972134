/** Preloader CSS */
#cf-preloader{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 99999;
    overflow: hidden;
}

#cf-preloader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
}