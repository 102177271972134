/***** Adjust Images */

.profile-image {
    height: 100%;
    width: 100%;
    min-height: 200px;
    max-width: 200px;
    background-position: center;
    background-repeat: no-repeat;  
    background-size: cover; 
}

.table-profile-image {
    height: 2.5rem;
    width: 2.5rem;
}

.histroy-card-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;  
    background-size: cover; 
}

.login-card-image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;  
    background-size: cover; 
}